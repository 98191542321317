import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import UnderConstruction from "../components/under-contstruction/under-construction"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <UnderConstruction/>
  </Layout>
)

export default IndexPage
