import * as React from 'react';

import Logo from '../../images/logo.png';
import EmailIcon  from '../../images/email-icon.png';
import './under-construction.scss';
import { useStaticQuery, graphql } from "gatsby"
import ContactForm from '../contact/contact-form';

const UnderConstruction = () => {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
                siteMetadata {
                    email
              }
            }
          }
        `
    )

    function decode(s) {
        var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
        var A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        for (i = 0; i < 64; i++) { e[A.charAt(i)] = i; }
        for (x = 0; x < L; x++) {
            c = e[s.charAt(x)]; b = (b << 6) + c; l += 6;
            while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
        }
        return r;
    };

    return (
        <>
            <div className="d-flex flex-column flex-lg-row w-100 h-100">
                <div className="w-lg-50 w-sm-100">
                    <div id="under-construction-logo-div" className="d-flex flex-column w-100 align-items-center">
                        <img src={Logo} alt="logo" id="under-construction-logo" />
                        <div id="under-construction-text" className="mt-5 w-75 text-center">
                            <p className='p-3 mb-0'>Deze website is momenteel onder constructie.
                            QN Technics is jouw ideale partner voor al uw elektriciteit en domotica projecten.
                            Voor meer informatie kan je mij steeds contacteren via onderstaande contactgegevens of vul gerust het contactformulier in.</p>
                        </div> 
                        <div className='mt-5 mb-5'>
                            <img id="email-icon" className='me-2' src={EmailIcon}/>{typeof window !== 'undefined' && decode(site.siteMetadata.email)}
                        </div> 
                    </div>
                </div>
                <div className="w-lg-50 h-100 w-sm-100 bg-primary">
                    <div id="under-contstruction-contact-form" className='p-3'>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    );
}

export default UnderConstruction;