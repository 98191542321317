import React, { useState } from 'react';
import Spinner from '../spinner/spinner';

const ContactForm = () => {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const [showThankYou, setShowThankYou] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        let data = {
            contactName: name,
            contactEmail: email,
            contactMessage: message
        }

        let postMessage = {
            method: "POST",
            body: JSON.stringify(data)
        };

        // connect with email function here
        const response = await fetch("/.netlify/functions/sendmail", postMessage);
        setIsLoading(false);

        if (response.ok) {
            // 200 response
            setShowThankYou(true);
            return
        } else {
            setShowError(true);
            console.log("api call to sendgrid failed");
        }
    }

    const resetForm = () => {
        setName("");
        setEmail("");
        setMessage("");
        setShowThankYou(false);
        setIsLoading(false);
        setShowError(false);
    }

    if (isLoading) {
        return <Spinner classes="mt-5" />
    }
  
    if(showError) {
        return (
            <div id="form-error" className="mt-5 d-flex flex-column align-items-center">
                <h3>Oeps er ging iets mis...</h3>
                <p>Probeer later opnieuw of contacteer ons gerust telefonisch of via gregory@qntechnics.be</p>
                <button className="btn btn-outline-primary" onClick={resetForm}>Terug</button>
            </div>
        )
    }

    if(showThankYou) {
        return (
            <div id="form-thankyou" className="mt-5 d-flex flex-column align-items-center">
                <h3>Bedankt voor je bericht!</h3>
                <p>Bedankt voor je bericht, we nemen zo snel mogelijk contact met je op.</p>
                <button className="btn btn-outline-primary" onClick={resetForm}>Terug</button>
            </div>
        )
    }

    return (
        <form id="contact-form" className="my-4" onSubmit={handleSubmit} method="POST"> 
            <div className="form-group mb-3">
                <label htmlFor="name">Naam</label>
                <input type="text" id="name" className="form-control" onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="email">E-mailadres</label>
                <input required type="email" id="email" className="form-control" aria-describedby="email" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="message">Bericht</label>
                <textarea className="form-control" rows="5" onChange={(e) => setMessage(e.target.value)}></textarea>
            </div>
            <p id="gdpr-form-message">Bovenstaande gegevens worden enkel en alleen gebruikt om u te contacteren. Deze zullen niet gebruikt worden voor commerciële doeleinden</p>
            <button type="submit" className="btn btn-outline-light">Verzenden</button>
        </form>
    );
}

export default ContactForm;