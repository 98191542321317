import React from 'react';

const Spinner = ({ classes }) => {
    let finalClasses = "";
    if(classes !== undefined) finalClasses = classes;

    return (
        <div className={"d-flex justify-content-center" + " " + finalClasses}>
            <div className="spinner-border" role="status">
                <span className="sr-only"></span>
            </div>
        </div>
    );
};

export default Spinner;